import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import enTranslations from "./locales/en.json";
import csTranslations from "./locales/cs.json";
import deTranslations from "./locales/de.json";
import plTranslations from "./locales/pl.json";
import esTranslations from "./locales/es.json";
import frTranslations from "./locales/fr.json";

const translations = {
  en: {
    translation: enTranslations,
  },
  cs: {
    translation: csTranslations,
  },
  de: {
    translation: deTranslations,
  },
  pl: {
    translation: plTranslations,
  },
  es: {
    translation: esTranslations,
  },
  fr: {
    translation: frTranslations,
  },
};

i18n.use(initReactI18next).init({
  resources: translations,
  lng: localStorage.getItem("loc") || "cs",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
