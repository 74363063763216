import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { useTranslation } from "react-i18next";
import { useFormValuesContext } from "src/core/context";
import { Box } from "@mui/material";

export const CustomLabel = ({ isSelected, label }: { isSelected: boolean; label: string }) => {
  return <span style={{ color: isSelected ? "#E31340" : "inherit" }}>{label}</span>;
};

const initialFormValues = {
  payment_method: localStorage.getItem("payment_method") || "card",
};

export const RadioButtonsPaymentsMobile: React.FC = () => {
  const { formValues, setFormValues } = useFormValuesContext();
  const [selectedValue, setSelectedValue] = React.useState<string>(
    initialFormValues.payment_method,
  );
  const { t } = useTranslation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setSelectedValue(newValue);
    localStorage.setItem("payment_method", newValue);
  };

  React.useEffect(() => {
    if (!formValues.payment_method || formValues.payment_method === "") {
      const storedValue = localStorage.getItem("payment_method") || "card";
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        payment_method: storedValue,
      }));
    }
  }, [formValues, setFormValues]);

  React.useEffect(() => {
    if (selectedValue !== formValues.payment_method) {
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        payment_method: selectedValue,
      }));
    }
  }, [selectedValue, formValues.payment_method, setFormValues]);

  return (
    <FormControl>
      <RadioGroup row name="row-radio-buttons-group" value={selectedValue} onChange={handleChange}>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexShrink: "0",
            whiteSpace: "nowrap",
          }}
        >
          <FormControlLabel
            sx={{ marginLeft: "0px", marginRight: "0px" }}
            value="card"
            control={<Radio />}
            label={<CustomLabel isSelected={selectedValue === "card"} label={t("card")} />}
          />
          {/* TODO: temporarily hidden the QR code payment method for tablet apps */}
          {/*<FormControlLabel*/}
          {/*  sx={{ marginLeft: "0px", marginRight: "0px" }}*/}
          {/*  value="qr_code"*/}
          {/*  control={<Radio />}*/}
          {/*  label={<CustomLabel isSelected={selectedValue === "qr_code"} label={t("QR")} />}*/}
          {/*/>*/}
        </Box>
      </RadioGroup>
    </FormControl>
  );
};
