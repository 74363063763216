import { Action, TicketFilterPayload, TicketFilterType } from "src/core/types/reduxTypes";

const initialState: { selectedFilter: string } = {
  selectedFilter: "",
};

export const TicketFilterReducer = (
  action: Action<TicketFilterType, TicketFilterPayload>,
  state = initialState,
): { selectedFilter: string } => {
  switch (action?.type) {
    case "SELECT_FILTER":
      return {
        ...state,
        selectedFilter: action.payload,
      };
    default:
      return state;
  }
};
