export const styles = {
  title: {
    color: "#062762",
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: "10px",
    display: "flex",
    alignItems: "center",
  },
  sudTitle: {
    whiteSpace: "nowrap",
    fontWeight: "600",
    color: "#000000",
    fontSize: "18px",
  },
  sudTitle2: {
    display: "flex",
    fontSize: "14px",
    whiteSpace: "noWrap",
  },
  notesRed: {
    paddingTop: "1px",
    color: "#E31340",
    fontSize: "14px",
    margin: "0px 0 0 10px",
    fontWeight: "400",
  },
  buttonDecrement: {
    border: "1px solid #B9B9B9",
    borderRadius: "5px",
    textDecoration: "none",
    padding: "10px 20px",
    "&.MuiButton-sizeMedium": {
      minWidth: "40px",
      height: "40px",
    },
  },
  buttonIncrement: {
    border: "1px solid #E31340",
    borderRadius: "5px",
    textDecoration: "none",
    padding: "10px 20px",
    "&.MuiButton-sizeMedium": {
      minWidth: "40px",
      height: "40px",
    },
  },
  buttonCount: {
    border: "1px solid #062762",
    borderRadius: "5px",
    textDecoration: "none",
    padding: "10px 20px",
    color: "#062762",
    fontWeight: "bold",
    "&.MuiButton-sizeMedium": {
      minWidth: "40px",
      height: "40px",
    },
    "&.MuiButtonBase-root.MuiButton-root": {
      textDecoration: "none",
      color: "#062762",
      fontWeight: "bold",
    },
  },
  text: {
    fontSize: "16px",
  },
  rule: {
    color: "#000000",
    fontSize: "16px",
    fontWeight: "400",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  buttonToGoSection: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonToGo: {
    position: "relative",
    width: "fit-content",
    height: "50px",
    padding: "0px 24px",
    backgroundColor: "#E31340",
    color: "white",
    textDecoration: "none",
    display: "flex",
    borderRadius: "25px",
  },
  ticket: {
    fontSize: "16px",
    marginTop: "10px",
    fontWeight: "400",
  },
};
