import React, { useState } from "react";
import styles from "./styles.module.css";
import logo from "../../../assets/logo_jakub_pos.svg";
import { clearFormValues, useFormValuesContext } from "src/core/context";
import { useGetRemoveReservation } from "src/core/queries/useGetRemoveReservation";
import { useNavigate } from "react-router-dom";
import { LOGIN_ROUTE, TICKET_TYPE_ROUTE } from "src/core/routing";

export const Logo: React.FC = () => {
  const { formValues, setFormValues } = useFormValuesContext();
  const navigate = useNavigate();
  const [executeQuery, setExecuteQuery] = useState<boolean>(false);
  const reservationId = formValues.reservation || "";
  const { refetch } = useGetRemoveReservation({ reservationId, executeQuery });

  const navigateToTicketType = () => {
    navigate(TICKET_TYPE_ROUTE);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const onSubmit = () => {
    setFormValues(clearFormValues);
    const savedName = localStorage.getItem("name");
    const savedEmail = localStorage.getItem("email");

    if (savedName !== null) {
      localStorage.setItem("name", savedName);
    }
    if (savedEmail !== null) {
      localStorage.setItem("email", savedEmail);
    }
    navigateToTicketType();
    // localStorage.clear();
    localStorage.removeItem("count1");
    localStorage.removeItem("count2");
    localStorage.removeItem("count3");
    localStorage.removeItem("count4");
    localStorage.removeItem("count5");
    localStorage.removeItem("count6");
    localStorage.removeItem("time");
    localStorage.removeItem("free_capacity");
    localStorage.removeItem("totalPrice");
    localStorage.removeItem("totalTickets");
    localStorage.removeItem("time_slot_id");
    localStorage.removeItem("loglevel");
    localStorage.removeItem("selectedSlotId");
    localStorage.removeItem("reservation");
    localStorage.removeItem("customer_paid");
    localStorage.removeItem("backupTotalPrice");
    localStorage.removeItem("discount");
  };

  return (
    <div className={styles.logoWrapper} onClick={onSubmit}>
      <img src={logo} alt="logo" className={styles.logo} />
    </div>
  );
};
