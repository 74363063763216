import { Box, Checkbox, Typography } from "@mui/material";
import { RadioButtonsPayments } from "../RadioButtonsPayments";
import QRCode from "react-qr-code";
import styless from "./styles.module.css";
import { useTranslation } from "react-i18next";
import { styles } from "./styles";
import { useFormValuesContext } from "src/core/context";
import { RadioButtonsPaymentsMobile } from "../RadioButtonsPaymentsMobile";
import React, { useEffect, useRef, useState } from "react";
import { useApplyDiscount } from "src/core/hooks";

export const QRCodeMobile: React.FC = () => {
  const { t } = useTranslation();
  const { formValues, setFormValues } = useFormValuesContext();
  const paymentMethod = formValues.payment_method;
  const paymentMethodQRCode = paymentMethod === "qr_code";
  const totalPrice = localStorage.getItem("totalPrice");

  const qrRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    qrRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  }, [paymentMethodQRCode]);

  const { isBrnopas30Checked, isSchoolChecked, handleCheckboxChange } = useApplyDiscount();

  return (
    <Box className={styless.container}>
      <Box className={styless.boxTwo}>
        <Box className={styless.paymentsWrapper}>
          <Typography
            sx={styles.notes}
            style={{
              fontWeight: "600",
              color: "#062762",
              fontSize: "18px",
            }}
          >
            {t("customer_is_pay_by")}
          </Typography>
          <RadioButtonsPaymentsMobile />
        </Box>
        <Box className={styless.discountsWrapper}>
          <Typography
            sx={styles.notes}
            style={{
              fontWeight: "600",
              color: "#062762",
              fontSize: "18px",
            }}
          >
            {t("customer_discount")}
          </Typography>
          <Box className={styless.customCheckboxWrapper}>
            <label className={styless.customCheckbox}>
              <input
                type="checkbox"
                onChange={(e) => handleCheckboxChange(e, "BRNOPASS")}
                checked={isBrnopas30Checked}
              />
              <span></span>
            </label>
            <span style={{ color: "#000000", fontWeight: "400" }}>Brnopass30</span>
          </Box>
          <Box className={styless.customCheckboxWrapper}>
            <label className={styless.customCheckbox}>
              <input
                type="checkbox"
                onChange={(e) => handleCheckboxChange(e, "SCHOOL")}
                checked={isSchoolChecked}
              />
              <span></span>
            </label>
            <span style={{ color: "#000000", fontWeight: "400" }}>Školy10</span>
          </Box>
        </Box>
      </Box>
      {paymentMethodQRCode && (
        <Box className={styless.boxFour} ref={qrRef}>
          <Box className={styless.boxFourTitle}>{t("qr_notes")}</Box>
          <QRCode
            value={`SPD*1.0*ACC:${window.QR_PAYMENT_ACCOUNT}*AM:${totalPrice}*CC:${window.QR_PAYMENT_CURRENCY}*MSG:${window.QR_PAYMENT_MESSAGE}*RN:${window.QR_PAYMENT_RETAIL_NAME}`}
          />
          <Box className={styless.boxFourPrice}>{`${totalPrice}Kč`}</Box>
        </Box>
      )}
    </Box>
  );
};
