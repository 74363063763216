import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { TICKET_TYPE_ROUTE } from "src/core/routing";
import { useTranslation } from "react-i18next";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Backdrop,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { clearFormValues, useFormValuesContext } from "src/core/context";
// import { useLogin } from "src/core/queries/useGetLogin";
import { usePostTokenAuth } from "src/core/queries/usePostTokenAuth";
import { useGetUserData } from "src/core/queries/useGetUserData";

import styles from "./styles.module.css";
import mainPhoto from "../../../assets/2023_11_12_sv. Jakub_vizualizace_prechod3@2x.png";
import logo from "../../../assets/logo_jakub_neg.svg";
import checkmarkIcon from "../../../assets/checkmark (1).svg";
import errorIcon from "../../../assets/error (1).svg";
import pic from "../../../assets/5821fe42b9e958160511b2284b35b06d.jpg";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { TokenAuthResult } from "core/types/TokenAuthResult";
import { UserData } from "src/core/types/UserData";

const MAX_LENGTH = 10;
type LoginPageProps = {
  setIsLoggedIn: (value: boolean) => void;
};

export const LoginPage: React.FC<LoginPageProps> = ({ setIsLoggedIn }) => {
  const { t } = useTranslation();

  const [loginFormData, setLoginFormData] = useState({
    username: "",
    password: "",
  });
  const [loginError, setLoginError] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 800);
  const [isSnackbarOpened, setIsSnackbarOpened] = useState<boolean>(false);
  const [isLoginLoading, setIsLoginLoading] = useState<boolean>(false);

  // const { mutate: loginMutate } = useLogin();
  const { mutate: postTokenAuthMutate } = usePostTokenAuth();
  const { mutate: postUserDataMutate } = useGetUserData();
  const navigate = useNavigate();
  const { formValues, setFormValues } = useFormValuesContext();

  const handleCloseSnackbar = () => {
    setIsSnackbarOpened(false);
  };

  const handleClickShowPassword = () => setIsPasswordVisible((prev) => !prev);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const updateMedia = () => {
    setIsMobile(window.innerWidth <= 800);
  };
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  interface LoginOptions {
    onSuccess: (tokenAuthResult: TokenAuthResult) => void;
    onError: (error: unknown) => void;
  }

  const loginOptions = useMemo<LoginOptions>(
    () => ({
      onSuccess: (tokenAuthResult) => {
        if (tokenAuthResult.token) {
          localStorage.setItem("isLoggedIn", "true");
          localStorage.setItem("name", tokenAuthResult.worker_name);
          localStorage.setItem("email", tokenAuthResult.worker_email);
          localStorage.setItem("token", tokenAuthResult.token);

          setIsLoggedIn(true);
          setFormValues((prevValues) => ({
            ...prevValues,
            name: tokenAuthResult.worker_name,
            email: tokenAuthResult.worker_email,
          }));

          navigate(`${TICKET_TYPE_ROUTE}`);
        } else {
          setLoginError("Nesprávné přihlašovací údaje");
          localStorage.setItem("isLoggedIn", "false");
          setIsLoggedIn(false);
        }
        setIsLoginLoading(false);
      },
      onError: (error) => {
        console.error("Network error:", error);
        setLoginError("Chyba síte");
        setIsLoginLoading(false);
      },
    }),
    [navigate, setFormValues, setIsLoggedIn],
  );

  const handleLogin = async () => {
    if (!loginFormData.username) {
      setLoginError("Vyplňte prosím uživatelské jméno");
      return;
    }
    if (!loginFormData.password) {
      setLoginError("Vyplňte prosím heslo");
      return;
    }

    setIsLoginLoading(true);
    try {
      postTokenAuthMutate(
        { username: loginFormData.username, password: loginFormData.password },
        loginOptions,
      );
    } catch (error) {
      console.error("Login failed:", error);
      setIsLoginLoading(false);
      setLoginError("Přihlášení zlyhalo");
    }
  };

  interface UserDataOptions {
    onSuccess: (userData: UserData) => void;
    onError: (error: unknown) => void;
  }

  useEffect(() => {
    if (isMobile) {
      setIsLoginLoading(true);
      const queryParameters = new URLSearchParams(window.location.search);
      const token = queryParameters.get("token");

      if (!token) {
        setIsLoginLoading(false);
        return;
      }
      try {
        postUserDataMutate(token, {
          onSuccess: (userData) => {
            if (userData.worker_name) {
              localStorage.setItem("isLoggedIn", "true");
              localStorage.setItem("name", userData.worker_name);
              localStorage.setItem("email", userData.worker_email);
              localStorage.setItem("token", token);

              setIsLoggedIn(true);
              setFormValues((prevValues) => ({
                ...prevValues,
                name: userData.worker_name,
                email: userData.worker_email,
              }));

              navigate(`${TICKET_TYPE_ROUTE}`);
            } else {
              setLoginError("Nesprávné přihlašovací údaje");
              localStorage.setItem("isLoggedIn", "false");
              setIsLoggedIn(false);
            }
            setIsLoginLoading(false);
          },
          onError: (error) => {
            console.error("Network error:", error);
            setLoginError("Chyba síte");
            setIsLoginLoading(false);
          },
        });
      } catch (error) {
        console.error("Login failed:", error);
        setIsLoginLoading(false);
        setLoginError("Přihlášení zlyhalo");
      }
    }
  }, [isMobile, navigate, postUserDataMutate, setFormValues, setIsLoggedIn]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setLoginFormData({
      ...loginFormData,
      [name]: value,
    });
  };

  useEffect(() => {
    // check for credentials in localStorage
    const loggedIn = localStorage.getItem("isLoggedIn") === "true";
    const username = localStorage.getItem("name");
    const email = localStorage.getItem("email");
    const token = localStorage.getItem("token");
    if (!loggedIn || !username || !email || !token) return;
    // if found, then reroute to app as usual
    setIsLoggedIn(true);
    setFormValues((prevValues) => ({
      ...prevValues,
      name: username,
      email: email,
    }));
    navigate(`${TICKET_TYPE_ROUTE}`);
  }, [navigate, setFormValues, setIsLoggedIn]);

  return (
    <Box className={styles.container}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoginLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Snackbar
        open={Boolean(loginError)}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={handleCloseSnackbar}
      >
        <Alert
          severity="error"
          variant="filled"
          sx={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}
        >
          {loginError}
        </Alert>
      </Snackbar>

      <Box className={styles.contentWrapper}>
        <Box className={styles.logoWrapper}>
          <img src={logo} className={styles.logo} alt="logo" />
        </Box>
        <Box className={styles.formWrapper} component="form">
          <Box className={styles.formTitle}>{t("tickets")}</Box>
          <TextField
            label="Uživatelské jméno"
            variant="outlined"
            color="primary"
            name="username"
            value={loginFormData.username}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            label="Heslo"
            variant="outlined"
            color="primary"
            type={isPasswordVisible ? "text" : "password"}
            name="password"
            value={loginFormData.password}
            onChange={handleInputChange}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Box onClick={handleLogin} className={styles.submitButton}>
            {t("button_text")}
          </Box>
        </Box>
        <Box className={styles.footerWrapper}>{t("rights_reserved")}</Box>
      </Box>
    </Box>
  );
};
