import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { HOMEPAGE_ROUTE, LOGIN_ROUTE } from "src/core/routing";

// Pages
import { LoginPage } from "./PageLogin";
import { MainPage } from "./MainPage";
import { useGetRemoveReservation } from "src/core/queries/useGetRemoveReservation";
import { useFormValuesContext } from "src/core/context";

const style = {
  wrapper: {
    margin: "auto",
    overflow: "hidden",
  },
};

const Main = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  const { formValues, setFormValues } = useFormValuesContext();
  const reservationId = formValues.reservation || "";
  const [executeQuery, setExecuteQuery] = useState<boolean>(false);
  const { data: cancelReservationData } = useGetRemoveReservation({ reservationId, executeQuery });

  useEffect(() => {
    const loggedIn = localStorage.getItem("isLoggedIn") === "true";
    const username = localStorage.getItem("name") || "";
    const userLogin = localStorage.getItem("email") || "";
    const token = localStorage.getItem("token") || "";

    if (loggedIn) {
      setIsLoggedIn(true);
    } else {
      localStorage.clear();
      if (window.location.pathname.includes(LOGIN_ROUTE)) return;
      navigate(LOGIN_ROUTE);
    }

    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      localStorage.clear();
      setExecuteQuery(true);
      // if (username) localStorage.setItem("username", username);
      // if (userLogin) localStorage.setItem("email", userLogin);
      localStorage.setItem("name", username || "");
      localStorage.setItem("email", userLogin || "");
      localStorage.setItem("token", token || "");
      localStorage.setItem("isLoggedIn", loggedIn ? "true" : "false");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [cancelReservationData?.status, navigate]);

  return (
    <Box style={style.wrapper}>
      <Routes>
        <Route
          path="/"
          element={<Navigate replace to={isLoggedIn ? HOMEPAGE_ROUTE : LOGIN_ROUTE} />}
        />
        <Route path={LOGIN_ROUTE} element={<LoginPage setIsLoggedIn={setIsLoggedIn} />} />
        {isLoggedIn && <Route path={HOMEPAGE_ROUTE} element={<MainPage />} />}
      </Routes>
    </Box>
  );
};

export default Main;
