import { FINANTIAL_CLOSURE_API_ROUTE } from "src/core/endpoints";
import axios from "axios";
import { useQuery } from "react-query";
import { finantialClosure } from "core/types/finantialClosure";

export const usePostFinantialClosure = ({
  from,
  to,
  enabled,
}: {
  from: string;
  to: string;
  enabled: boolean;
}) => {
  const fetchFinantialClosure: () => Promise<finantialClosure> = async () => {
    if (!enabled) return;
    const res = await axios.post(
      `${window.BASE_API_URL}/${FINANTIAL_CLOSURE_API_ROUTE}/`,
      {
        from: from,
        to: to,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    return res.data;
  };

  return useQuery(`fetchFinantialClosure-${from}-${to}`, fetchFinantialClosure, {
    refetchOnWindowFocus: false,
    enabled: !!from && !!to && enabled,
  });
};
