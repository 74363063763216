export const styles = {
  button: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    background: "#062762",
    borderRadius: "5px",
    cursor: "pointer",
  },
  back: {
    color: "black",
    cursor: "pointer",
    fontWeight: "600",
    fontSize: "24px",
  },
};
