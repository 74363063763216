import dayjs from "dayjs";
import * as React from "react";
import { createContext, useState, useContext } from "react";

type FormValuesType = {
  name: string;
  email: string;
  customer_paid: boolean;
  payment_method: string;
  timer_time: { minutes: number; seconds: number } | null;
  send_promo: boolean;
  free_capacity: number | null;
  reservation: string | null;
  order_type: string;
  time_slot_id: number | null;
  type: string;
  date: Date;
  time: string;
  phone_number: string | number;
  validEmail: string;
  discount: string | null;
  discount_amount: number | null;
  discount_percent_amount: number | null;
  finalPrice: number | null;
  tickets: { type: number; amount: number }[];
  totalTickets: number;
  totalPrice: number | null;
  timeSlotString: string;
  ticketsCount: number;
  language: string;
  selectedSlotId: number | null;
  lastSubmittedSlotId: number | null;
  count1: number | null;
  count2: number | null;
  count3: number | null;
  count4: number | null;
  count5: number | null;
  count6: number | null;
  count7: number | null;
  countPrice1: number | null;
  countPrice2: number | null;
  countPrice3: number | null;
  countPrice4: number | null;
  countPrice5: number | null;
  countPrice6: number | null;
};

const getTickets = (ticketsJSON: string | null) => {
  const parsed = JSON.parse(localStorage.getItem("tickets") || "");
  return parsed;
};

const getTimerTime = (timerJSON: string | null) => {
  const parsed = JSON.parse(localStorage.getItem("timer_time") || "");
  return parsed;
};

export const clearFormValues = {
  name: "",
  email: "",
  customer_paid: false,
  payment_method: "",
  timer_time: null,
  send_promo: false,
  free_capacity: null,
  reservation: null,
  time_slot_id: null,
  order_type: "",
  type: "",
  date: new Date(),
  time: "",
  phone_number: "",
  validEmail: "",
  discount: null,
  discount_amount: null,
  discount_percent_amount: null,
  finalPrice: 0,
  timeSlotString: "",
  tickets: [],
  totalTickets: 0,
  totalPrice: 0,
  ticketsCount: 0,
  language: localStorage.getItem("loc") || "cs",
  selectedSlotId: null,
  lastSubmittedSlotId: null,
  count1: 0,
  count2: 0,
  count3: 0,
  count4: 0,
  count5: 0,
  count6: 0,
  count7: 0,
  countPrice1: null,
  countPrice2: null,
  countPrice3: null,
  countPrice4: null,
  countPrice5: null,
  countPrice6: null,
};

export const defaultFormValues = {
  lastSubmittedSlotId: Number(localStorage.getItem("selectedSlotId")) || null,
  selectedSlotId: Number(localStorage.getItem("selectedSlotId")) || null,
  isLoggedIn: localStorage.getItem("isLoggedIn") || false,
  name: localStorage.getItem("name") || "",
  email: localStorage.getItem("email") || "cash",
  customer_paid: Boolean(localStorage.getItem("customer_paid")),
  payment_method: localStorage.getItem("payment_method") || "card",
  timer_time: localStorage.getItem("timer_time")
    ? getTimerTime(localStorage.getItem("timer_time"))
    : null,
  send_promo: Boolean(localStorage.getItem("send_promo")),
  free_capacity: localStorage.getItem("free_capacity")
    ? Number(localStorage.getItem("free_capacity"))
    : null,
  reservation: localStorage.getItem("reservation")
    ? String(localStorage.getItem("reservation"))
    : null,
  time_slot_id: localStorage.getItem("time_slot_id")
    ? Number(localStorage.getItem("time_slot_id"))
    : null,
  order_type: localStorage.getItem("order_type") || "single",
  type: localStorage.getItem("type") || "",
  date: localStorage.getItem("date") ? dayjs(localStorage.getItem("date")).toDate() : new Date(),
  time: localStorage.getItem("time") || "",
  phone_number: localStorage.getItem("phone") || "",
  validEmail: localStorage.getItem("email") || "",
  discount: localStorage.getItem("discount") || null,
  discount_amount: Number(localStorage.getItem("discount_percent_amount")) || null,
  discount_percent_amount: Number(localStorage.getItem("discount_percent_amount")) || null,
  finalPrice: Number(localStorage.getItem("finalPrice")) || 0,
  timeSlotString: localStorage.getItem("timeSlotString") || "",
  tickets: localStorage.getItem("tickets") ? getTickets(localStorage.getItem("tickets")) : [],
  totalTickets: Number(localStorage.getItem("totalTickets")) || 0,
  totalPrice: Number(localStorage.getItem("totalPrice")) || 0,
  ticketsCount: Number(localStorage.getItem("ticketsCount")) || clearFormValues.ticketsCount,
  language: localStorage.getItem("language") || clearFormValues.language,
  count1: Number(localStorage.getItem("baby")) || 0,
  count2: Number(localStorage.getItem("child")) || 0,
  count3: Number(localStorage.getItem("adult")) || 0,
  count4: Number(localStorage.getItem("senior")) || 0,
  count5: Number(localStorage.getItem("family")) || 0,
  count6: Number(localStorage.getItem("ztp")) || 0,
  count7: Number(localStorage.getItem("ztp")) || 0,
  countPrice1: Number(localStorage.getItem("count1Price")) || null,
  countPrice2: Number(localStorage.getItem("count2Price")) || null,
  countPrice3: Number(localStorage.getItem("count3Price")) || null,
  countPrice4: Number(localStorage.getItem("count4Price")) || null,
  countPrice5: Number(localStorage.getItem("count5Price")) || null,
  countPrice6: Number(localStorage.getItem("count6Price")) || null,
};

type FormValuesContextTypes = {
  formValues: FormValuesType;
  setFormValues: React.Dispatch<React.SetStateAction<FormValuesType>>;
};

export const FormValuesContext = createContext<FormValuesContextTypes>(
  {} as FormValuesContextTypes,
);

export const useFormValuesContext = () => useContext(FormValuesContext);

export const FormValuesContextProvider = ({ children }: { children: any }) => {
  const [formValues, setFormValues] = useState<FormValuesType>(defaultFormValues);

  return (
    <FormValuesContext.Provider value={{ formValues, setFormValues }}>
      {children}
    </FormValuesContext.Provider>
  );
};
