import React from "react";
import { STOP_RESERVATION_API_ROUTE } from "../endpoints";
import { useQuery } from "react-query";
import axios from "axios";
import { Timer } from "../types/timer";
import { useNavigate } from "react-router-dom";
import { clearFormValues, useFormValuesContext } from "../context";
import { LOGIN_ROUTE } from "../routing";

export const useGetRemoveReservation = ({
  reservationId,
  executeQuery,
}: {
  reservationId: string;
  executeQuery: boolean;
}) => {
  const fetchStopReservation = async (): Promise<Timer> => {
    const config = {
      headers: {
        Authorization:
          process.env.NODE_ENV === "production"
            ? `Basic ${window.btoa(
                `${process.env.REACT_APP_API_USERNAME}:${process.env.REACT_APP_API_PASSWORD}`,
              )}`
            : "",
      },
    };
    const response = await axios.get(
      `${window.BASE_API_URL}/${STOP_RESERVATION_API_ROUTE}/${reservationId}`,
      config,
    );
    return response.data;
  };
  const navigate = useNavigate();
  const { setFormValues } = useFormValuesContext();
  return useQuery<Timer, Error>(`fetchStopReservation-${reservationId}`, fetchStopReservation, {
    refetchOnWindowFocus: false,
    enabled: !!reservationId && executeQuery,
    onSuccess: () => {
      setFormValues(clearFormValues);
      const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";
      const username = localStorage.getItem("name") || "";
      const userLogin = localStorage.getItem("email") || "";
      const token = localStorage.getItem("token") || "";
      localStorage.clear();
      if (isLoggedIn) {
        localStorage.setItem("name", username);
        localStorage.setItem("email", userLogin);
        localStorage.setItem("token", token);
        localStorage.setItem("isLoggedIn", "true");
      }
      navigate(LOGIN_ROUTE);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
    onError: (error) => {
      console.error("Error:", error.message);
    },
  });
};
