import { useState, useEffect } from "react";
import { useFormValuesContext } from "src/core/context";

export const useApplyDiscount = () => {
  const { formValues, setFormValues } = useFormValuesContext();
  const [isBrnopas30Checked, setIsBrnopas30Checked] = useState(false);
  const [isSchoolChecked, setIsSchoolChecked] = useState(false);
  const [updatedPrice, setUpdatedPrice] = useState<number | null>(null);

  const calculateDiscountedPrice = (total: number, discountType: string) => {
    if (discountType === "BRNOPASS") {
      return Math.round(total * 0.7);
    } else if (discountType === "SCHOOL") {
      return Math.round(total * 0.9);
    }
    return total;
  };

  useEffect(() => {
    let total = parseInt(localStorage.getItem("totalPrice") || "0");
    const backupTotalPrice = localStorage.getItem("backupTotalPrice");

    if (isBrnopas30Checked || isSchoolChecked) {
      const discountType = isBrnopas30Checked ? "BRNOPASS" : "SCHOOL";
      localStorage.setItem("discount", discountType);
      localStorage.setItem("backupTotalPrice", total.toString());
      total = calculateDiscountedPrice(total, discountType);
      localStorage.setItem("totalPrice", total.toString());
      setUpdatedPrice(total);
    } else if (backupTotalPrice) {
      localStorage.setItem("totalPrice", backupTotalPrice);
      setUpdatedPrice(parseInt(backupTotalPrice));
      localStorage.removeItem("backupTotalPrice");
      localStorage.removeItem("discount");
    }
  }, [isBrnopas30Checked, isSchoolChecked]);

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    discountType: string,
  ) => {
    const backupTotalPrice = localStorage.getItem("backupTotalPrice");
    if (backupTotalPrice) {
      localStorage.setItem("totalPrice", backupTotalPrice);
      setUpdatedPrice(parseInt(backupTotalPrice));
      localStorage.removeItem("backupTotalPrice");
      localStorage.removeItem("discount");
    }

    if (discountType === "BRNOPASS") {
      setIsBrnopas30Checked(event.target.checked);
      if (event.target.checked) {
        setIsSchoolChecked(false);
      }
    } else if (discountType === "SCHOOL") {
      setIsSchoolChecked(event.target.checked);
      if (event.target.checked) {
        setIsBrnopas30Checked(false);
      }
    }
  };

  useEffect(() => {
    if (updatedPrice !== null && updatedPrice !== formValues.totalPrice) {
      setFormValues((prevValues) => ({ ...prevValues, totalPrice: updatedPrice }));
    }
  }, [updatedPrice, setFormValues, formValues.totalPrice]);

  return {
    isBrnopas30Checked,
    isSchoolChecked,
    handleCheckboxChange,
  };
};
