import { Slot } from "src/core/types";
import { Action, TicketType } from "src/core/types/reduxTypes";

const initialState: { selectedTicket: Slot | null } = {
  selectedTicket: null,
};

export const SelectedTicketReducer = (
  action: Action<TicketType, any>,
  state = initialState,
): { selectedTicket: Slot | null } => {
  switch (action?.type) {
    case "SET_SELECTED_TICKET":
      return {
        ...state,
        selectedTicket: action.payload,
      };
    case "RESET_SELECTED_TICKET":
      return {
        ...state,
        selectedTicket: null,
      };
    default:
      return state;
  }
};
