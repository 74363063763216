import { useMutation } from "react-query";
import axios from "axios";
import { MANUAL_PAYMENT_API_ROUTE } from "../endpoints";
import { ManualPayment } from "../types/manual_payment";
import { clearFormValues, useFormValuesContext } from "src/core/context";
import { useNavigate } from "react-router-dom";

export const usePostManualPayment = () => {
  const { formValues, setFormValues } = useFormValuesContext();

  return useMutation(
    (submitData: ManualPayment) => {
      const config = {
        headers: {
          Authorization:
            process.env.NODE_ENV === "production"
              ? `Basic ${window.btoa(
                  `${process.env.REACT_APP_API_USERNAME}:${process.env.REACT_APP_API_PASSWORD}`,
                )}`
              : "",
        },
      };
      return axios.post(`${window.BASE_API_URL}/${MANUAL_PAYMENT_API_ROUTE}`, submitData, config);
    },
    {
      onSuccess: (response) => {
        if (response.data.result === "NOT_AUTHENTICATED") {
          setFormValues(clearFormValues);
          localStorage.clear();
        }
      },
      onError: (error) => {
        console.error("Error during manual payment:", error);
        return;
      },
    },
  );
};
