import axios from "axios";
import { CHECK_CAPACITY_API_ROUTE } from "../endpoints";
import { checkCapacity } from "../types/checkCapacity";
import { useQuery } from "react-query";

export const useGetCapacity = ({ reservationId, pos }: { reservationId: string; pos: string }) => {
  const fetchCapacity: () => Promise<checkCapacity> = async () => {
    const config = {
      headers: {
        Authorization:
          process.env.NODE_ENV === "production"
            ? `Basic ${window.btoa(
                `${process.env.REACT_APP_API_USERNAME}:${process.env.REACT_APP_API_PASSWORD}`,
              )}`
            : "",
      },
    };
    const res = await axios.get(
      `${window.BASE_API_URL}/${CHECK_CAPACITY_API_ROUTE}/${reservationId}?pos=${pos}`,
      config,
    );
    // console.log("useGetCapacity", res.data);
    return res.data;
  };

  return useQuery(`fetchCapacity-${reservationId}`, fetchCapacity, {
    refetchOnWindowFocus: false,
    enabled: !!reservationId,
  });
};
