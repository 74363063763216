import { useQuery } from "react-query";
import axios from "axios";
import { MONTH_API_ROUTE } from "../endpoints";

export const useGetSlotsMonthly = ({
  id,
  month,
  visitors,
  pos,
}: {
  id: string;
  month: string;
  visitors: number;
  pos: string;
}) => {
  const getFullDates = (
    obejctArr: { date: string; weekday: number; free_capacity: number | null }[],
  ) => {
    const dates: string[] = [];

    obejctArr.forEach((object, i) => {
      if (
        object.free_capacity === null ||
        object.free_capacity < visitors ||
        object.free_capacity === 0
      ) {
        dates.push(object.date);
      }
    });

    return dates;
  };

  const fetchSlots: () => Promise<string[]> = async () => {
    const config = {
      headers: {
        Authorization:
          process.env.NODE_ENV === "production"
            ? `Basic ${window.btoa(
                `${process.env.REACT_APP_API_USERNAME}:${process.env.REACT_APP_API_PASSWORD}`,
              )}`
            : "",
      },
    };
    const res = await axios.get(
      `${window.BASE_API_URL}/${MONTH_API_ROUTE}/${month}?pos=${pos}`,
      config,
    );
    return getFullDates(res.data.days);
  };

  return useQuery(`fetchSlotsMonthly-${id}-${month}`, fetchSlots, {
    refetchOnWindowFocus: false,
    enabled: !!id && !!month,
  });
};
