import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import penIcon from "../../../assets/edit.svg";
import calendarIcon from "../../../assets/calendar_grey.svg";
import arrowDown from "../../../assets/arrow_down.svg";
import { styles } from "./style";
import styless from "./styles.module.css";
import { format } from "date-fns";

import { useFormValuesContext } from "src/core/context";
import { useGetSlots } from "src/core/queries/useGetSlots";
import { useGetSlotsMonthly } from "src/core/queries/useGetSlotsMonthly";
import { getMonthString } from "src/core/helpers";
import { CalendarDialog } from "src/components/atoms/CalendarDialog";
import { Slots } from "../../atoms/Slots";
import dayjs from "dayjs";
import { UsePostPreReservation } from "src/core/queries/usePostPreReservation";
import { CheckOutStep, Tab } from "src/core/types";

interface DateAndTimeProps {
  updateCheckOutState: (newTabsState: Tab[], newPanelsState: CheckOutStep[]) => void;
  tabs: Tab[];
  step: CheckOutStep;
  panels: CheckOutStep[];
  style: any;
  isActiveTab: CheckOutStep;
  hidden: CheckOutStep[];
  setTimer: (value: boolean) => void;
}

export const DateAndTime: React.FC<DateAndTimeProps> = ({
  updateCheckOutState,
  tabs,
  step,
  panels,
  style,
  isActiveTab,
  hidden,
  setTimer,
}) => {
  const { formValues, setFormValues } = useFormValuesContext();
  const { t } = useTranslation();
  const currentDate = new Date();

  const selectedDate = formValues.date;
  // console.log("selectedDate", selectedDate);

  // const userName = localStorage.getItem("email");
  const token = localStorage.getItem("token");
  // queries
  const { data: slotsData, refetch: refetchSlots } = useGetSlots({
    id: formValues.order_type,
    date: formValues.date || currentDate,
    pos: "kostnice",
  });

  const { data: unavailableDays, refetch: refetchSlotsMonth } = useGetSlotsMonthly({
    id: formValues.order_type,
    month: getMonthString(formValues.date),
    visitors: formValues.ticketsCount,
    pos: "kostnice",
  });

  const { mutate: mutatePreReservation, data: dataPreReservation } = UsePostPreReservation();

  const sectionRef = useRef<HTMLElement | null>(null);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);
  const updateMedia = () => {
    setIsMobile(window.innerWidth <= 800);
  };
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  //pen click function
  const penClick = () => {
    const newState = panels.map((panel: CheckOutStep) => {
      if (panel.key === "1") {
        return { ...panel, isEnabled: !panel.isEnabled };
      }
      if (panel.key === "2") {
        return { ...panel, isEnabled: !panel.isEnabled };
      }
      if (panel.key === "3") {
        return { ...panel };
      }
      if (panel.key === "4") {
        return { ...panel };
      }
      return { ...panel, isEnabled: false };
    });
    updateCheckOutState(tabs, newState);
  };

  const onSubmit = () => {
    const formattedDate = dayjs(formValues.date).format("YYYY-MM-DD");
    const reservationId = localStorage.getItem("reservation");
    const timeSlotId = localStorage.getItem("time_slot_id") || formValues.time_slot_id || "";
    // Post pre-reservation
    if (timeSlotId) {
      const lastSubmittedSlotId = localStorage.getItem("lastSubmittedSlotId");

      mutatePreReservation({
        reservation: reservationId,
        date: String(formattedDate || ""),
        time_slot_id: Number(timeSlotId || formValues.time_slot_id || ""),
        pos: isMobile ? String("kustod") : String("kostnice"),
        token: String(token),
      });
    } else {
      console.log("Time slot ID is null, cannot proceed with the submission.");
    }
    setTimer(true);
    const newTabsState = tabs.map((tab: Tab) => {
      if (tab.key === "2") {
        return { ...tab, isTabActive: true };
      } else if (tab.key === "1") {
        return { ...tab, isTabDone: true, isTabActive: false };
      } else {
        return { ...tab, isTabActive: false };
      }
    });

    const newPanelsState = panels.map((panel: CheckOutStep) => {
      if (panel.key === "1") {
        return { ...panel, isEnabled: false, isPen: true, isStepFinished: true };
      }
      if (panel.key === "2") {
        return { ...panel, isEnabled: true };
      }
      return { ...panel, isEnabled: false, isPen: false };
    });
    updateCheckOutState(newTabsState, newPanelsState);
  };

  const handleCalendarClick = async (value: Date) => {
    const formattedDate = dayjs(value).format("YYYY-MM-DD");

    if (unavailableDays && unavailableDays.includes(formattedDate)) {
      return;
    }
    setFormValues({ ...formValues, date: value });
    localStorage.setItem("date", formattedDate);
    await refetchSlots();
  };

  useEffect(() => {
    refetchSlots();
    refetchSlotsMonth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    sectionRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  }, [step.isEnabled]);

  useEffect(() => {
    if (formValues.date && formValues.order_type) refetchSlots();
  }, [refetchSlots, formValues.date, formValues.order_type]);

  useEffect(() => {
    if (formValues.order_type) refetchSlotsMonth();
  }, [refetchSlots, refetchSlotsMonth, formValues.order_type]);

  const isSlotSelected = formValues.time_slot_id != null;
  if (hidden) {
    return null;
  }

  const originalDateString = formValues.date;
  const parsedDate = new Date(originalDateString);
  const formattedDate = format(parsedDate, "dd. M. yyyy");
  const selectedTime = formValues.time || localStorage.getItem("time");

  return (
    <Box ref={sectionRef}>
      {step.hidden ? null : (
        <Box style={style}>
          {step.isEnabled ? (
            <Box className={styless.box}>
              <Box className={styless.mainRow}>
                {/* <Box className={styless.calendar}>
                  <CalendarDialog
                    onClick={handleCalendarClick}
                    selectedValue={selectedDate}
                    fullSlots={unavailableDays || []}
                  />
                </Box> */}
                <Box className={styless.slots} style={{ width: "100%" }}>
                  <div className={styless.slotsWrapper}>
                    <Slots
                      onSubmit={() => onSubmit()}
                      selectedDate={selectedDate || currentDate}
                      onClick={handleCalendarClick}
                      fullSlots={slotsData ? slotsData.slots : []}
                    />
                  </div>
                </Box>
              </Box>
              {/* {dataPreReservation?.data.result === "TIMESLOT_EXPIRED" && (
                <LimitModalComponent open={openModal} handleClose={handleCloseModal} />
              )} */}
              {/* <LimitModalComponent open={openModal} handleClose={handleCloseModal} /> */}
            </Box>
          ) : (
            step.isStepFinished && (
              <Box className={styless.closedBox}>
                <div className={styless.rowBox}>
                  {step.isStepFinished && (
                    <>
                      <img src={calendarIcon} />
                      {"   "}
                      <Typography sx={{ color: "#051C4766", fontWeight: "600" }}>
                        {t("date_time")} {formattedDate} {", "}
                        {selectedTime}
                      </Typography>
                    </>
                  )}
                  {!step.isStepFinished && (
                    <>
                      <img src={calendarIcon} />
                      {"   "}
                      <Typography sx={{ color: "#051C4766", fontWeight: "600" }}>
                        {t("date_time")} {formattedDate} {","}
                        {selectedTime}
                      </Typography>
                    </>
                  )}
                </div>
                {step.isPen && (
                  <Box className={styless.pen} onClick={penClick}>
                    {t("change")}
                  </Box>
                )}
              </Box>
            )
          )}
        </Box>
      )}
    </Box>
  );
};
