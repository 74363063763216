import dayjs from "dayjs";
import i18n from "src/i18n/config";

export const getStartOfDay = (date: Date) => {
  return dayjs(date).startOf("day").toDate();
};

export const getFutureDates = ({ date, futureIndex }: { date: Date; futureIndex: number }) => {
  const dates = [];
  const currentDate = new Date(date);

  for (let i = 0; i < futureIndex; i++) {
    dates.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dates;
};

export const getDayName = (date: Date) => {
  return date.toLocaleDateString(i18n.language, { weekday: "short" });
};

export const compareDates = (date1: Date, date2: Date) => {
  if (date1.setHours(0, 0, 0, 0) === date2.setHours(0, 0, 0, 0)) {
    return true;
  } else {
    return false;
  }
};

export const getDateString = (date: Date) => {
  if (date) {
    const doo = new Date(date.getTime() + Math.abs(date.getTimezoneOffset() * 60000));
    return doo.toISOString().split("T")[0];
  }
  return null;
};

export const getMonthString = (date: Date) => {
  if (date) {
    const doo = new Date(date.getTime() + Math.abs(date.getTimezoneOffset() * 60000));
    const dateArr = doo.toISOString().split("-");
    return `${dateArr[0]}-${dateArr[1]}`;
  }
  return "";
};
