import React, { useEffect, useState } from "react";
import { Box, Checkbox, Typography } from "@mui/material";
import { RadioButtonsPayments } from "../RadioButtonsPayments";
import QRCode from "react-qr-code";
import styless from "./styles.module.css";
import { useTranslation } from "react-i18next";
import { styles } from "./styles";
import { useFormValuesContext } from "src/core/context";
import { useApplyDiscount } from "src/core/hooks";

export const QRCodeDesktop: React.FC = () => {
  const { t } = useTranslation();
  const { formValues, setFormValues } = useFormValuesContext();
  const paymentMethod = formValues.payment_method;
  const paymentMethodQRCode = paymentMethod === "qr_code";
  const totalPrice = localStorage.getItem("totalPrice");

  const { isBrnopas30Checked, isSchoolChecked, handleCheckboxChange } = useApplyDiscount();

  return (
    <>
      <Box className={styless.boxTwo}>
        <Typography
          sx={styles.notes}
          style={{
            fontWeight: "600",
            color: "#062762",
            marginRight: "20px",
            fontSize: "18px",
          }}
        >
          {t("customer_is_pay_by")}
        </Typography>
        <RadioButtonsPayments />
        <Box className={styless.customCheckboxWrapper}>
          <label className={styless.customCheckbox}>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, "BRNOPASS")}
              checked={isBrnopas30Checked}
            />
            <span></span>
          </label>
          <span style={{ color: "#000000", fontWeight: "400" }}>Brnopass30</span>
        </Box>
        <Box className={styless.customCheckboxWrapper}>
          <label className={styless.customCheckbox}>
            <input
              type="checkbox"
              onChange={(e) => handleCheckboxChange(e, "SCHOOL")}
              checked={isSchoolChecked}
            />
            <span></span>
          </label>
          <span style={{ color: "#000000", fontWeight: "400" }}>Školy10</span>
        </Box>
      </Box>
    </>
  );
};
