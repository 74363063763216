export const preferButtons = [
  {
    id: "everytime",
    text_content: "Kdykoli",
  },
  {
    id: "morning",
    text_content: "Dopoledne",
  },
  {
    id: "evening",
    text_content: "Odpoledne",
  },
];
