import { Box, Button, Typography } from "@mui/material";
import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import timerIcon from "../../../assets/timer.svg";
import { styles } from "./styles";
import { clearFormValues, useFormValuesContext } from "src/core/context";
import { useGetRemoveReservation } from "src/core/queries/useGetRemoveReservation";
import { useNavigate } from "react-router-dom";
import { HOMEPAGE_ROUTE } from "src/core/routing";
import styless from "./styles.module.css";

export const CountdownTimer: React.FC = () => {
  const { formValues, setFormValues } = useFormValuesContext();
  const [executeQuery, setExecuteQuery] = useState<boolean>(false);
  const reservationId = formValues.reservation || "";
  const { data: timerData } = useGetRemoveReservation({ reservationId, executeQuery });
  const { t } = useTranslation();

  const [hidden, setHidden] = useState<boolean>(false);
  const [seconds, setSeconds] = useState<number>(0);
  const [minutes, setMinutes] = useState<number>(15);
  const [timerEnded, setTimerEnded] = useState<boolean>(false);
  const navigate = useNavigate();

  const navigateToHome = useCallback(() => {
    navigate(HOMEPAGE_ROUTE);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [navigate]);

  useEffect(() => {
    if (minutes === 0 && seconds === 0) {
      setTimerEnded(true);
      setExecuteQuery(true);
      return;
    }
    const timer = setInterval(() => {
      setSeconds((prevSeconds) => (prevSeconds === 0 ? 59 : prevSeconds - 1));
      if (seconds === 0) {
        setMinutes((prevMinutes) => prevMinutes - 1);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [minutes, seconds]);

  useEffect(() => {
    if (timerEnded) {
      if (timerData?.status === "OK") {
        setFormValues(clearFormValues);
        localStorage.clear();
        navigateToHome();
      } else if (timerData?.status === "ERROR") {
        console.error("Timer Error:", timerData.message);
      }
      setHidden(true);
    }
  }, [timerEnded, timerData, setFormValues, navigateToHome]);

  const formatTime = (value: number) => (value < 10 ? `0${value}` : value.toString());

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);
  const updateMedia = () => {
    setIsMobile(window.innerWidth <= 800);
  };
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  return (
    <Box className={styless.box}>
      {!hidden && (
        <Box className={styless.countdownWrapper}>
          {isMobile ? (
            <Box className={styless.countdownMobile}>
              <Box className={styless.countdownTopMobile}>
                <img src={timerIcon} className={styless.countdownIcon} />
                {formatTime(minutes)}:{formatTime(seconds)}
              </Box>
              <Box className={styless.countdownNoteMobile}>{t("timer_notes")}</Box>
            </Box>
          ) : (
            <Box className={styless.countdownDesktop}>
              <Box className={styless.countdownLeftDesktop}>
                <img src={timerIcon} className={styless.countdownIcon} />
              </Box>
              <Box className={styless.countdownRightDesktop}>
                <Box className={styless.countdownTimeDesktop}>
                  {formatTime(minutes)}:{formatTime(seconds)}
                </Box>
                <Box className={styless.countdownNoteDesktop}>{t("timer_note1")}</Box>
              </Box>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};
