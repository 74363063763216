import React, { useEffect, useState } from "react";
import { Box, Backdrop, CircularProgress } from "@mui/material";
import css from "./styles.module.css";

import closureIcon from "../../../assets/finantial_closure_red.svg";
import downloadIcon from "../../../assets/download2.svg";
import HomeHeader from "../../molecules/Header";
import { usePostFinantialClosure } from "src/core/queries/usePostFinantialClosure";

const DATE_FORMAT = "cs-CZ";

const getDateTimeString = (filter: string): string => {
  const date = new Date();
  switch (filter) {
    case "today":
      return date.toLocaleDateString(DATE_FORMAT);
    case "yesterday":
      date.setDate(date.getDate() - 1);
      return date.toLocaleDateString(DATE_FORMAT);
    case "thisMonth":
      return date.toLocaleDateString(DATE_FORMAT, { month: "long", year: "numeric" });
    case "thisYear":
      return date.getFullYear().toString();
    default:
      return "";
  }
};

const getDateTimeStringAPI = (filter: string): { from: string; to: string } => {
  const date = new Date();
  const daysInMonth = (month: number, year: number): number => {
    return new Date(year, month, 0).getDate();
  };
  switch (filter) {
    case "today":
      return {
        from: date.toISOString().split("T")[0],
        to: date.toISOString().split("T")[0],
      };
    case "yesterday":
      date.setDate(date.getDate() - 1);
      return {
        from: date.toISOString().split("T")[0],
        to: date.toISOString().split("T")[0],
      };
    case "thisMonth":
      return {
        from: `${date.getFullYear()}-${date.getMonth() + 1}-01`,
        to: `${date.getFullYear()}-${date.getMonth() + 1}-${daysInMonth(
          date.getMonth() + 1,
          date.getFullYear(),
        )}`,
      };
    case "thisYear":
      return {
        from: `${date.getFullYear()}-01-01`,
        to: `${date.getFullYear()}-12-31`,
      };
    default:
      return {
        from: "",
        to: "",
      };
  }
};

const filterOptions: { label: string; display: string }[] = [
  { label: "today", display: "Dnes" },
  { label: "yesterday", display: "Včera" },
  { label: "thisMonth", display: "Tento měsíc" },
  { label: "thisYear", display: "Tento rok" },
  { label: "custom", display: "Vlastní" },
];

const TableRow = ({
  title,
  subtitle = "",
  quantity,
  total,
  isFinal = false,
  isHeading = false,
}: {
  title: string;
  subtitle?: string;
  quantity: string | number;
  total: string | number;
  isFinal?: boolean;
  isHeading?: boolean;
}) => (
  <Box className={`${css.tableGridRow} ${isFinal ? css.tableGridRowFinal : ""}`}>
    <Box className={css.gridItemTitleWrapper}>
      <Box className={css.gridItemTitle}>{title}</Box>
      {subtitle && <Box className={css.gridItemSubtitle}>{subtitle}</Box>}
    </Box>
    <Box className={`${!isHeading ? css.gridItemQuantity : css.gridItemHeading}`}>{quantity}</Box>
    <Box className={`${!isHeading ? css.gridItemTotal : css.gridItemHeading}`}>{total}</Box>
  </Box>
);

export const PageFinantialClosure = () => {
  const [dateTimeString, setDateTimeString] = useState<{
    label: string;
    displayValue: string;
    from: string;
    to: string;
  }>({
    label: "today",
    displayValue: getDateTimeString("today"),
    from: getDateTimeStringAPI("today").from,
    to: getDateTimeStringAPI("today").to,
  });

  const {
    data: finantialClosureData,
    isLoading: isFinantialClosureLoading,
    refetch: refetchFinantialClosure,
  } = usePostFinantialClosure({
    from: dateTimeString.from,
    to: dateTimeString.to,
    enabled: dateTimeString.from !== "" && dateTimeString.to !== "",
  });

  const handleFilterChange = (filter: string) => {
    const dateInputs = document.querySelectorAll("input[type=date]");
    dateInputs.forEach((input) => {
      (input as HTMLInputElement).value = "";
    });
    setDateTimeString({
      label: filter,
      displayValue: getDateTimeString(filter),
      from: getDateTimeStringAPI(filter).from,
      to: getDateTimeStringAPI(filter).to,
    });
  };

  const handleChangeDate = (type: "from" | "to") => (e: React.ChangeEvent<HTMLInputElement>) => {
    setDateTimeString({
      ...dateTimeString,
      [type]: e.target.value,
    });
  };

  const handlePrint = () => {
    if (!dateTimeString.from || !dateTimeString.to || !dateTimeString.displayValue) return;
    const dateFrom = new Date(dateTimeString.from);
    const dateTo = new Date(dateTimeString.to);
    if (dateFrom > dateTo) {
      alert("Datumy musí být ve správném pořadí.");
      return;
    }
    window.print();
  };

  useEffect(() => {
    if (!dateTimeString.from || !dateTimeString.to) return;
    if (dateTimeString.label !== "custom") return;
    setDateTimeString((prevState) => ({
      ...prevState,
      displayValue: `${new Date(dateTimeString.from).toLocaleDateString(DATE_FORMAT)} - ${new Date(
        dateTimeString.to,
      ).toLocaleDateString(DATE_FORMAT)}`,
    }));
  }, [dateTimeString.from, dateTimeString.label, dateTimeString.to, finantialClosureData]);

  useEffect(() => {
    console.log("NOW");
  }, [isFinantialClosureLoading]);

  return (
    <Box className={css.container}>
      <Backdrop open={isFinantialClosureLoading} style={{ zIndex: 99 }}>
        {isFinantialClosureLoading && <CircularProgress color="inherit" />}
      </Backdrop>
      <HomeHeader />
      <Box className={css.innerWrapper}>
        <Box className={css.titleWrapper}>
          <img src={closureIcon} alt="Finantial closure icon" />
          <Box className={css.titleText}>Finanční uzávěrka</Box>
        </Box>

        <Box className={css.contentBlockWrapper}>
          {/* HEADER TITLE*/}
          <Box className={css.contentBlockTitle}>Vstupenky prohlídky</Box>

          {/* PRINT HEADING (VISIBLE WHEN PRINTING) */}
          <Box className={css.printHeadingWrapper}>
            <Box style={{ fontWeight: "700", fontSize: "20px" }}>
              Finanční uzávěrka za užívatele
            </Box>
            <Box className={css.printHeadingContentWrapper}>
              <Box className={css.printHeadingLeft}>
                <Box>Období: {dateTimeString.displayValue}</Box>
                <Box>Organizace: TIC BRNO, příspěvková organizace</Box>
                <Box>Stanice: všechny</Box>
                <Box>Uživatel: {localStorage.getItem("name")}</Box>
              </Box>
              <Box className={css.printHeadingRight}>
                <Box>Dátum tisku: {new Date().toLocaleString("cs-CZ").toString().slice(0, -3)}</Box>
              </Box>
            </Box>
          </Box>

          {/* FILTERS ROW WITH EXPORT BUTTON */}
          <Box className={css.filtersExportWrapper}>
            <Box className={css.filtersWrapper}>
              <Box>Období</Box>
              {filterOptions.map(({ label, display }) => (
                <Box key={label} className={css.filterOuterWrapper}>
                  <Box
                    key={label}
                    onClick={() => handleFilterChange(label)}
                    className={`${css.filterWrapper} ${
                      dateTimeString.label === label ? css.filterWrapperSelected : ""
                    }`}
                  >
                    {display}
                  </Box>
                  <Box
                    className={css.filterDateRange}
                    style={{
                      display:
                        dateTimeString.label === "custom" && label === "custom" ? "flex" : "none",
                    }}
                  >
                    <input type={"date"} onChange={handleChangeDate("from")} />
                    <input type={"date"} onChange={handleChangeDate("to")} />
                  </Box>
                </Box>
              ))}
            </Box>
            <Box onClick={handlePrint} className={css.exportWrapper}>
              <img src={downloadIcon} alt="Download icon" />
              <span>Export</span>
            </Box>
          </Box>

          {/* TABLE */}
          <Box className={css.tableWrapper}>
            <Box className={css.tableTitleWrapper}>
              <Box className={css.tableTitle}>Vstupenky pokladna</Box>
              <Box className={css.tableTotal}>Celkem</Box>
            </Box>
            <TableRow title={""} quantity={"Počet"} total={"Suma (Kč)"} isHeading={true} />
            <TableRow
              title={"Dospělý"}
              subtitle={"plné vstupné"}
              quantity={finantialClosureData?.tickets.adult.amount || ""}
              total={finantialClosureData?.tickets.adult.sum || ""}
            />
            <TableRow
              title={"Rodinné"}
              subtitle={"2 dospělí a max. 2 děti"}
              quantity={finantialClosureData?.tickets.family.amount || ""}
              total={finantialClosureData?.tickets.family.sum || ""}
            />
            <TableRow
              title={"Děti do 6 let"}
              subtitle={""}
              quantity={finantialClosureData?.tickets.baby.amount || ""}
              total={finantialClosureData?.tickets.baby.sum || ""}
            />
            <TableRow
              title={"Děti 6-15 let"}
              subtitle={""}
              quantity={finantialClosureData?.tickets.child.amount || ""}
              total={finantialClosureData?.tickets.child.sum || ""}
            />
            <TableRow
              title={"Snížené"}
              subtitle={"Osoby nad 65 let, studenti"}
              quantity={finantialClosureData?.tickets.senior.amount || ""}
              total={finantialClosureData?.tickets.senior.sum || ""}
            />
            <TableRow
              title={"ZTP"}
              subtitle={""}
              quantity={finantialClosureData?.tickets.ztp.amount || ""}
              total={finantialClosureData?.tickets.ztp.sum || ""}
            />
            <TableRow
              title={"Celkem"}
              quantity={finantialClosureData?.tickets.summary.amount || ""}
              total={finantialClosureData?.tickets.summary.sum || ""}
              isFinal={true}
            />
          </Box>

          {/* TABLE */}
          <Box className={css.tableWrapper}>
            <Box className={css.tableTitleWrapper}>
              <Box className={css.tableTitle}>Vstupenky pokladna</Box>
              <Box className={css.tableTotal}>Celkem</Box>
            </Box>
            <TableRow
              title={"Hotově"}
              quantity={""}
              total={finantialClosureData?.payments.cash || ""}
            />
            <TableRow
              title={"Kartou"}
              quantity={""}
              total={finantialClosureData?.payments.card || ""}
            />
            <TableRow
              title={"Celkem"}
              quantity={""}
              total={finantialClosureData?.payments.sum || ""}
              isFinal={true}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
