import { useQuery } from "react-query";
import { TICKETS_GENERATE_API_ROUTE } from "../endpoints";

export const usePrintTickets = ({ reservationId }: { reservationId: string }) => {
  const fetchPrint = async () => {
    if (!reservationId) {
      throw new Error("Reservation ID is missing");
    }

    const pdfUrl = `${window.BASE_API_URL}/${reservationId}/${TICKETS_GENERATE_API_ROUTE}`;
    return { pdfUrl };
  };

  return useQuery(`fetchPrint-${reservationId}`, fetchPrint, {
    refetchOnWindowFocus: false,
    enabled: !!reservationId,
  });
};
