import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { CountdownTimer } from "src/components/atoms/CountdownTimer";
import Footer from "src/components/molecules/Footer";
import HomeHeader from "src/components/molecules/Header";
import CheckOut from "src/components/organisms/CheckOut";
import { useFormValuesContext } from "src/core/context";
import { useGetSlots } from "src/core/queries/useGetSlots";
import style from "./styles.module.css";
import { useNavigate } from "react-router-dom";

export const PagePurchase = () => {
  const navigate = useNavigate();
  const [timer, setTimer] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);
  const updateMedia = () => {
    setIsMobile(window.innerWidth <= 800);
  };
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  useEffect(() => {
    if (
      !localStorage.getItem("token") ||
      !localStorage.getItem("isLoggedIn") ||
      !localStorage.getItem("name") ||
      !localStorage.getItem("email")
    ) {
      localStorage.clear();
      navigate("/login");
    }
  }, [navigate]);

  return (
    <Box sx={{ margin: "auto" }}>
      <div className={style.header}>{isMobile ? null : <HomeHeader />}</div>
      {timer && <CountdownTimer />}
      <CheckOut setTimer={setTimer} />
    </Box>
  );
};
