import { Box, Checkbox, FormControlLabel } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { useFormValuesContext } from "src/core/context";
import styless from "./styles.module.css";

export const CheckboxComponent: React.FC<{
  onPaymentChange: (payment: boolean) => void;
  isChecked: boolean;
}> = ({ onPaymentChange, isChecked }) => {
  const { t } = useTranslation();
  const { formValues, setFormValues } = useFormValuesContext();

  const agreementForm = useForm({
    defaultValues: {
      Payment: isChecked,
    },
    reValidateMode: "onChange",
    mode: "onSubmit",
  });
  const { control } = agreementForm;

  const handleChange = useCallback(
    (value: boolean) => {
      const newFormValues = {
        ...formValues,
        customer_paid: value,
      };
      setFormValues(newFormValues);
      localStorage.setItem("customer_paid", value.toString());
    },
    [formValues, setFormValues],
  );

  // useEffect(() => {
  //   console.log("Updated customer_paid: ", formValues.customer_paid);
  //   agreementForm.setValue("Payment", isChecked);
  //   handleChange(isChecked);
  // }, [formValues.customer_paid, isChecked, agreementForm, handleChange]);

  useEffect(() => {
    // console.log("Updated customer_paid: ", formValues.customer_paid);
    agreementForm.setValue("Payment", isChecked);
    handleChange(isChecked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChecked, agreementForm]);

  const onCheckboxChange = () => {
    const PaymentChecked = agreementForm.getValues("Payment");
    handleChange(PaymentChecked);
    onPaymentChange(PaymentChecked);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    agreementForm.setValue("Payment", event.target.checked);
    onCheckboxChange();
  };

  return (
    <Box>
      <form>
        <Box className={styless.rowAgreement}>
          <Controller
            name="Payment"
            control={control}
            render={({ field: { value }, fieldState: { error } }) => (
              <FormControlLabel
                style={{ margin: "0", padding: "0" }}
                label={
                  <div style={{ display: "flex" }} className={styless.textBlock}>
                    <span className={styless.text}>{t("customer_have_paid_by")}</span>
                  </div>
                }
                control={<Checkbox checked={isChecked} onChange={handleCheckboxChange} />}
              />
            )}
          />
        </Box>
      </form>
    </Box>
  );
};
