import { useQuery } from "react-query";
import axios from "axios";
import { SLOTS_API_ROUTE } from "../endpoints";
import { Slot } from "../types";
import { getDateString } from "../helpers";

export const useGetSlots = ({ id, date, pos }: { id: string | null; date: Date; pos: string }) => {
  const dateString = getDateString(date);

  const fetchSlots: () => Promise<{ slots: Slot[]; requestDate: Date; pos: string }> = async () => {
    const config = {
      headers: {
        Authorization:
          process.env.NODE_ENV === "production"
            ? `Basic ${window.btoa(
                `${process.env.REACT_APP_API_USERNAME}:${process.env.REACT_APP_API_PASSWORD}`,
              )}`
            : "",
      },
    };
    const res = await axios.get(
      `${window.BASE_API_URL}/${SLOTS_API_ROUTE}/${dateString}?pos=${pos}`,
      config,
    );
    return { slots: res.data.slots, requestDate: date, pos: pos };
  };

  return useQuery(`fetchSlots-${id}-${date}`, fetchSlots, {
    refetchOnWindowFocus: false,
    enabled: !!id && !!date,
  });
};
