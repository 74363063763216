import { useQuery } from "react-query";
import axios from "axios";
import { TICKETS_API_ROUTE } from "../endpoints";
import { TicketTypes, TourList } from "../types";

export const useGetTickets = (isMobile: boolean | null) => {
  const fetchTickets: () => Promise<TourList> = async () => {
    const config = {
      headers: {
        Authorization:
          process.env.NODE_ENV === "production"
            ? `Basic ${window.btoa(
                `${process.env.REACT_APP_API_USERNAME}:${process.env.REACT_APP_API_PASSWORD}`,
              )}`
            : "",
      },
    };
    const res = await axios.get(
      `${window.BASE_API_URL}/${TICKETS_API_ROUTE}${isMobile ? "/?pos=kostnice-webview" : ""}`,
      config,
    );
    const ticketTypes = res.data.types;
    return ticketTypes;
  };

  return useQuery(`fetchTickets`, fetchTickets, {
    refetchOnWindowFocus: false,
  });
};
