import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import timeIcon from "../../../assets/hodiny_cervene.svg";
import line from "../../../assets/pngwing.com.png";
import { useFormValuesContext } from "src/core/context";
import { useNavigate } from "react-router-dom";
import { Slot } from "src/core/types";
import checkMark from "../../../assets/checkmark (1).svg";
import styles from "./styles.module.css";
import { RootState } from "src/core/redux/store";
import { useDispatch, useSelector } from "react-redux";
import ArrowSend from "../../../assets/arrowSend.svg";
import PreferButtons from "src/components/molecules/PreferButtons";
import redArrow from "../../../assets/button_send 2.svg";
import TimePreferButton from "components/atoms/TimePreferButton";
import { preferButtons } from "src/core/helpers/preferbuttons";

export const Slots = ({
  selectedDate,
  onClick,
  fullSlots,
  onSubmit,
}: {
  selectedDate: Date;
  onClick: (value: Date) => void;
  onSubmit: () => void;
  fullSlots: {
    id: number;
    time: string;
    basic_price: number;
    capacity: number;
    visitors: number;
  }[];
}) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);
  const updateMedia = () => {
    setIsMobile(window.innerWidth <= 800);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  // const selectedFilters = useSelector((state: RootState) => state.ticketFilters?.selectedFilter);

  const [selectedFilters, setSelectedFilters] = useState<string>("everytime");

  const filterSlotsByDayTime = () => {
    return fullSlots.filter((el) => {
      const [hours, minutes] = el.time.split(":").map(Number);

      switch (selectedFilters) {
        case "morning":
          return hours < 12 || (hours === 12 && minutes === 0);
        case "evening":
          return hours > 12 || (hours === 12 && minutes > 0);
        default:
          return true;
      }
    });
  };

  const style: Record<string, React.CSSProperties> = {
    button: {
      backgroundColor: "#FFFFFF",
      textDecoration: "none",
      borderRadius: "5px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      padding: "10px",
    },
    time: {
      fontSize: "20px",
      fontWeight: "600",
    },
    line: {
      color: "red",
      width: "36px",
      margin: "0px 10px",
    },
    price: {
      fontSize: "20px",
      fontWeight: "600",
      color: "#E31340",
    },
    text: {
      color: "#062762",
      fontWeight: "bold",
      fontSize: "18px",
      margin: "25px 0 20px 0",
      display: "flex",
      alignItems: "center",
    },
  };

  const { t } = useTranslation();
  const { formValues, setFormValues } = useFormValuesContext();
  const navigate = useNavigate();
  const [selectedSlot, setSelectedSlot] = useState<Slot | null>(null);
  const [selectedSlotId, setSelectedSlotId] = useState<boolean | null>(null);
  const [selectedSlotTime, setSelectedSlotTime] = useState("");
  const [selectedSlotDate, setSelectedSlotDate] = useState(Date);
  const [hoveredButton, setHoveredButton] = useState<number | null>(null);

  const handleMouseEnter = (id: number) => {
    setHoveredButton(id);
  };

  const handleMouseLeave = () => {
    setHoveredButton(null);
  };

  const notEnoughAvailableSpots = fullSlots.some(
    (slot) => slot.capacity - (slot.visitors || 0) < formValues.ticketsCount,
  );

  const handleSlotClick = async (slot: Slot) => {
    const isSlotSelected = selectedSlot && selectedSlot.id === slot.id;

    if (isSlotSelected) {
      setSelectedSlot(null);
      setSelectedSlotId(null);
      setSelectedSlotTime("");
      setSelectedSlotDate(Date);

      if (selectedSlotDate) {
        return;
      }
      setFormValues({
        ...formValues,
        time_slot_id: null,
        free_capacity: null,
        time: "",
        date: new Date(),
      });

      localStorage.setItem("time_slot_id", "");
      localStorage.setItem("freeCapacity", "");
      localStorage.setItem("time", "");
      localStorage.setItem("date", "");
      localStorage.removeItem("selectedSlotId");
    } else {
      setFormValues({
        ...formValues,
        time_slot_id: slot.id,
        time: slot.time,
        free_capacity: slot.capacity - slot.visitors,
      });

      localStorage.setItem("time_slot_id", String(slot.id));
      localStorage.setItem("free_capacity", String(slot.capacity - slot.visitors));
      localStorage.setItem("time", String(slot.time));
      localStorage.setItem("selectedSlotId", slot.id.toString());

      setSelectedSlot(slot);
      setSelectedSlotId(true);
      setSelectedSlotTime(slot.time.slice(0, 5));

      onSubmit();
    }
  };

  useEffect(() => {
    const savedSlotId = localStorage.getItem("selectedSlotId");

    if (savedSlotId) {
      const savedSlot = fullSlots.find((slot) => {
        return slot.id === Number(savedSlotId);
      });

      if (savedSlot) {
        setSelectedSlot(savedSlot);
        setSelectedSlotId(true);
      }
    }
  }, [fullSlots]);

  return (
    <Box className={styles.container}>
      <Box className={styles.title}>
        <img src={timeIcon} />
        {[t("calendar_title_time")]}
      </Box>
      <div className={styles.preferButtons}>
        {/*<PreferButtons />*/}
        {preferButtons.map((el) => (
          <Box
            key={el.id}
            onClick={() => setSelectedFilters(el.id)}
            className={`${styles.filterButton} ${
              selectedFilters === el.id ? styles.filterButtonActive : ""
            }`}
          >
            {el.text_content}
          </Box>
        ))}
      </div>

      <Box className={styles.wrapper}>
        {filterSlotsByDayTime().map((slot) => {
          const soldOut = (slot.visitors || 0) >= slot.capacity;
          const onlyOneAvailableSpots = slot.capacity - (slot.visitors || 0) === 1;
          const fromTwoToFourAvailableSpots =
            slot.capacity - (slot.visitors || 0) === 2 ||
            slot.capacity - (slot.visitors || 0) === 4;
          const availableSpots = slot.capacity - (slot.visitors || 0);

          const isSelected = selectedSlot && slot.id === selectedSlot.id;
          return (
            <Button
              onMouseEnter={() => handleMouseEnter(slot.id)}
              onMouseLeave={handleMouseLeave}
              key={slot.id}
              disabled={soldOut || notEnoughAvailableSpots}
              onClick={() => handleSlotClick(slot)}
              className={styles.button}
              sx={{
                ...style.button,
                textDecoration: "none",
                color: isSelected ? "#E31340" : "#062762",
                border: isSelected ? "2px solid #E31340" : "1px solid #E1E1E1",
                "&:hover": {
                  borderColor: "#E31340",
                  textDecoration: "none",
                  backgroundColor: "rgba(255, 255, 255, 1)",
                },
              }}
            >
              <div className={styles.innerButtonWrapper}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      ...style.time,
                      color: "rgba(6, 39, 98, 1) !important",
                      textDecoration: "none",
                      "&:hover": {
                        borderColor: "#E31340",
                        textDecoration: "none",
                      },
                    }}
                  >
                    {slot.time.slice(0, 5)}
                  </Typography>
                  {/*{isMobile ? null : <img src={line} className={styles.line} />}*/}
                  {/* {isMobile ? null : <span style={style.price}>{`${slot.basic_price},-`}</span>} */}
                </div>
                <Typography
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "14px",
                    color: onlyOneAvailableSpots || soldOut || isSelected ? "#E31340" : "#062762",
                    fontWeight: onlyOneAvailableSpots || soldOut ? "600" : "300",
                  }}
                >
                  {soldOut
                    ? t("sold_out")
                    : fromTwoToFourAvailableSpots
                    ? `${availableSpots} ${[t("from_2_to_4_available_tickets")]}`
                    : onlyOneAvailableSpots
                    ? `${availableSpots} ${[t("1_available_ticket")]}`
                    : `${availableSpots} ${[t("5_and_more_available_tickets")]}`}
                </Typography>
                <div className={styles.innerButtonArrowWrapper}>
                  <img
                    src={hoveredButton === slot.id ? redArrow : ArrowSend}
                    style={{ margin: "0 0 0 0" }}
                    className={styles.arrow}
                  />
                </div>
              </div>
            </Button>
          );
        })}
      </Box>
    </Box>
  );
};
