import axios from "axios";
import { useQuery } from "react-query";

export const useGetSVGTicket = ({ reservation_id }: { reservation_id: string }) => {
  const fetchTicket = async () => {
    const res = await axios.get(`${window.BASE_API_URL}/${reservation_id}/generate_ticket_svg`);
    return res.data.tickets;
  };

  const { data, status, refetch } = useQuery(`get-svg-ticket-${reservation_id}`, fetchTicket, {
    refetchOnWindowFocus: false,
    enabled: false,
  });

  return { data, status, refetch };
};
