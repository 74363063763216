export const styles = {
  title: {
    color: "#062762",
    fontSize: "16px",
    fontWeight: "bold",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  content: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    minHeight: "250px",
  },
  notes: {
    color: "#000000",
    fontWeight: "400",
    fontSize: "16px",
  },
  notesColumn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    marginRight: "20px",
  },
  buttonToGo: {
    position: "relative",
    padding: "0px 12px",
    backgroundColor: "#E31340",
    color: "white",
    display: "flex",
    justifyContent: "center",
    borderRadius: "25px",
  },
  buttonConfirmation: {
    width: "401px",
    height: "50px",
    position: "relative",
    padding: "0px 12px !important",
    backgroundColor: "#2F4D82",
    color: "white",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "25px",
  },
  closedBox: {
    width: "1260px",
    height: "84px",
    color: "#B9B9B9",
    backgroundColor: "#F8F8F8",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px 50px",
  },
  pen: {
    width: "27px",
    height: "27px",
    cursor: "pointer",
  },
};
