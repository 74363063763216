import { useMutation } from "react-query";
import axios from "axios";
import { PRE_RESERVATION_API_ROUTE } from "../endpoints";
import { PreReservation } from "../types";
import { clearFormValues, useFormValuesContext } from "../context";

export const UsePostPreReservation = () => {
  const { formValues, setFormValues } = useFormValuesContext();
  const mutation = useMutation(
    (submitData: PreReservation) => {
      const config = {
        headers: {
          Authorization:
            process.env.NODE_ENV === "production"
              ? `Basic ${window.btoa(
                  `${process.env.REACT_APP_API_USERNAME}:${process.env.REACT_APP_API_PASSWORD}`,
                )}`
              : "",
        },
      };
      return axios.post(`${window.BASE_API_URL}/${PRE_RESERVATION_API_ROUTE}`, submitData, config);
    },
    {
      onSuccess: (response) => {
        let reservation;
        let freeCapacity;

        switch (response.data.result) {
          case "OK":
            reservation = response.data.reservation;
            if (reservation) {
              setFormValues({ ...formValues, reservation: reservation });
              localStorage.setItem("reservation", reservation);
            }
            break;

          case "LIMIT_EXCEEDED":
            freeCapacity = response.data.free_capacity;
            setFormValues({ ...formValues, free_capacity: freeCapacity });
            localStorage.setItem("free_capacity", freeCapacity);
            break;

          case "RESERVATION_EXPIRED":
            setFormValues(clearFormValues);
            localStorage.clear();
            break;

          case "NOT_AUTHENTICATED":
            setFormValues(clearFormValues);
            localStorage.clear();
            break;

          case "TIMESLOT_EXPIRED":
            break;

          default:
            break;
        }
      },
      onError: (error) => {
        console.error("Error during reservation:", error);
      },
    },
  );
  return mutation;
};
