import axios from "axios";
import { useMutation } from "react-query";
import { TOKEN_AUTH_API_ROUTE } from "src/core/endpoints";
import { TokenAuthBody } from "core/types/TokenAuthBody";
import { TokenAuthResult } from "core/types/TokenAuthResult";

export const usePostTokenAuth = () => {
  const config = {
    headers: {
      Authorization:
        process.env.NODE_ENV === "production"
          ? `Basic ${window.btoa(
              `${process.env.REACT_APP_API_USERNAME}:${process.env.REACT_APP_API_PASSWORD}`,
            )}`
          : "",
    },
  };
  const fetchTokenAuth = async ({
    username,
    password,
  }: TokenAuthBody): Promise<TokenAuthResult> => {
    const res = await axios.post(
      `${window.BASE_API_URL}/${TOKEN_AUTH_API_ROUTE}/`,
      {
        username,
        password,
      },
      config,
    );
    if (res.status !== 200) throw new Error(`Token auth request failed with code ${res.status}`);
    return res.data;
  };
  return useMutation(fetchTokenAuth);
};
