import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material";

import { LocalizationProvider } from "@mui/x-date-pickers";
import "dayjs/locale/cs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

// Components
import Main from "./components/pages/Main";
import theme from "./theme";
import { QueryClient, QueryClientProvider } from "react-query";
import { FormValuesContextProvider } from "./core/context";
import i18n from "./i18n/config";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import { store } from "src/core/redux/store";
const queryClient = new QueryClient();

const App = () => {
  const [locale, setLocale] = React.useState<string>(i18n.language);

  useEffect(() => {
    const handleUnload = () => {
      // Perform actions before the component unloads
      localStorage.removeItem("date");
    };
    window.addEventListener("unload", handleUnload);
    return () => {
      window.removeEventListener("unload", handleUnload);
    };
  }, []);

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>
          <I18nextProvider i18n={i18n}>
            <BrowserRouter>
              <ThemeProvider theme={theme}>
                <FormValuesContextProvider>
                  <Main />
                </FormValuesContextProvider>
              </ThemeProvider>
            </BrowserRouter>
          </I18nextProvider>
        </LocalizationProvider>
      </QueryClientProvider>
    </Provider>
  );
};

export default App;
