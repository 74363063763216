import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import colorPayment from "../../../assets/payment_color.svg";
import logo from "../../../assets/logo_jakub_pos.svg";
import { styles } from "./styles";
import penIcon from "../../../assets/edit.svg";
import { CheckOutStep, Reservation, Tab } from "src/core/types";
import { useFormValuesContext } from "src/core/context";
import { format } from "date-fns";
import greyCard from "../../../assets/payment_gray.svg";
import styless from "./styles.module.css";
import { RadioButtonsPayments } from "src/components/atoms/RadioButtonsPayments";
import { CheckboxComponent } from "src/components/atoms/Checkbox";
import arrow from "../../../assets/arrow_right.svg";
import check from "../../../assets/checkmark_big.svg";
import cross from "../../../assets/cross.svg";
import { usePostManualPayment } from "src/core/queries/usePostManualPayment";
import { ManualPayment } from "src/core/types/manual_payment";
// import QR from "../../../assets/QR.svg";
import QRCode from "react-qr-code";
import { useGetTickets } from "src/core/queries/useGetTickets";
import { QRCodeMobile } from "src/components/atoms/QRMobile";
import { QRCodeDesktop } from "src/components/atoms/QRDesktop";
import { Payment } from "@mui/icons-material";

interface RecapPaymentProps {
  updateCheckOutState: (newTabsState: Tab[], newPanelsState: CheckOutStep[]) => void;
  step: CheckOutStep;
  panels: CheckOutStep[];
  style?: any;
  tabs: Tab[];
}

export const RecapPayment: React.FC<RecapPaymentProps> = ({
  updateCheckOutState,
  step,
  panels,
  style,
  tabs,
}) => {
  const { t } = useTranslation();

  // penClick function
  const penClick = () => {
    const newState = panels.map((panel: CheckOutStep) => {
      if (panel.key === "1") {
        return { ...panel };
      }
      if (panel.key === "2") {
        return { ...panel };
      }
      if (panel.key === "3") {
        return { ...panel, isEnabled: !panel.isEnabled };
      }
      if (panel.key === "4") {
        return { ...panel };
      }
      return { ...panel, isEnabled: false };
    });
    updateCheckOutState(tabs, newState);
  };

  // info for bill
  const { formValues, setFormValues } = useFormValuesContext();
  const originalDateString = formValues.date;
  const parsedDate = new Date(originalDateString);
  const formattedDate = format(parsedDate, "dd. M. yyyy");
  const selectedTime = formValues.time || localStorage.getItem("time");

  const totalTickets = localStorage.getItem("totalTickets");
  const totalPrice = localStorage.getItem("totalPrice");
  const ticketsChose = formValues.order_type;

  const baby = localStorage.getItem("count1");
  const child = localStorage.getItem("count2");
  const adult = localStorage.getItem("count3");
  const senior = localStorage.getItem("count4");
  const family = localStorage.getItem("count5");
  const ztp = localStorage.getItem("count6");
  const free = localStorage.getItem("count7");

  const sectionRef = useRef<HTMLElement | null>(null);

  // Queries
  const { mutate: mutateReservation } = usePostManualPayment();

  // Post reservation
  const onSubmit = () => {
    const postData: ManualPayment = {
      reservation: formValues.reservation,
      payment_method: formValues.payment_method,
      token: String(localStorage.getItem("token")),
    };

    const appliedDiscount = localStorage.getItem("discount");
    if (appliedDiscount !== null) {
      postData.discount = appliedDiscount;
    }

    mutateReservation(postData);
    const newTabsState = tabs.map((tab: Tab) => {
      if (tab.key === "4") {
        return { ...tab, isTabActive: true };
      } else if (tab.key === "3") {
        return { ...tab, isTabDone: true, isTabActive: false };
      } else {
        return { ...tab, isTabActive: false };
      }
      return tab;
    });

    const newState = panels.map((panel: CheckOutStep) => {
      if (panel.key === "1") {
        return { ...panel, isEnabled: false, isPen: true, hidden: true };
      }
      if (panel.key === "2") {
        return { ...panel, isEnabled: false, isPen: true, isStepFinished: true, hidden: true };
      }
      if (panel.key === "3") {
        return { ...panel, isEnabled: false, isPen: true, hidden: true, isStepFinished: true };
      }
      if (panel.key === "4") {
        return { ...panel, isEnabled: true, hidden: false };
      }
      return { ...panel, isEnabled: false, isPen: false, hidden: true };
    });
    updateCheckOutState(newTabsState, newState);
  };

  const [showCheckboxAndButton, setShowCheckboxAndButton] = useState<boolean>(true);
  const [PaymentChecked, setPaymentChecked] = useState<boolean>(false);

  const handleCrossClick = () => {
    setShowCheckboxAndButton(true);
    setPaymentChecked(false);
  };

  const handleCheckClick = () => {
    setPaymentChecked(true);
    setShowCheckboxAndButton(true);
  };

  const handlePaymentChange = (payment: boolean) => {
    setPaymentChecked(payment);
    setShowCheckboxAndButton(!payment);
  };

  const babyInfo = baby && parseInt(baby) > 0 ? `${baby} x ${t("children")}` : 0;
  const childInfo = child && parseInt(child) > 0 ? `${child} x ${t("children")}` : 0;
  const adultInfo = adult && parseInt(adult) > 0 ? `${adult} x ${t("adult")}` : 0;
  const seniorInfo = senior && parseInt(senior) > 0 ? `${senior} x ${t("senior")}` : 0;
  const familyInfo = family && parseInt(family) > 0 ? `${family} x ${t("family")}` : 0;
  const ztpInfo = ztp && parseInt(ztp) > 0 ? `${ztp} x ${t("ztp")}` : 0;
  const freeInfo = free && parseInt(free) > 0 ? `${free} x ${t("free")}` : 0;

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);
  const updateMedia = () => {
    setIsMobile(window.innerWidth <= 800);
  };
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  const { data: ticketTypes } = useGetTickets(isMobile);
  const babyPrice = ticketTypes?.find((ticket) => ticket.name === "baby")?.price;
  const childPrice = ticketTypes?.find((ticket) => ticket.name === "child")?.price;
  const adultPrice = ticketTypes?.find((ticket) => ticket.name === "adult")?.price;
  const seniorPrice = ticketTypes?.find((ticket) => ticket.name === "senior")?.price;
  const familyPrice = ticketTypes?.find((ticket) => ticket.name === "family")?.price;
  const ztpPrice = ticketTypes?.find((ticket) => ticket.name === "ztp")?.price;
  const freePrice = ticketTypes?.find((ticket) => ticket.name === "free")?.price;

  const babyTotalPrice = Number(babyPrice) * Number(baby);
  const childTotalPrice = Number(childPrice) * Number(child);
  const adultTotalPrice = Number(adultPrice) * Number(adult);
  const seniorTotalPrice = Number(seniorPrice) * Number(senior);
  const familyTotalPrice = Number(familyPrice) * Number(family);
  const ztpTotalPrice = Number(ztpPrice) * Number(ztp);
  const freeTotalPrice = Number(freePrice) * Number(free);

  const paymentMethod = formValues.payment_method;
  const paymentMethodCard = paymentMethod === "card";
  const paymentMethodQRCode = paymentMethod === "qr_code";

  useEffect(() => {
    sectionRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  }, [step.isEnabled]);

  return (
    <Box ref={sectionRef}>
      {step.hidden ? null : (
        <Box style={style}>
          {step.isEnabled ? (
            <Box
              className={styless.box}
              style={{
                height: paymentMethodCard
                  ? "517px !important"
                  : paymentMethodQRCode
                  ? "717px !important"
                  : "517px !important",
              }}
            >
              <Box className={styless.contentBox}>
                <Box className={styless.titleSection}>
                  <img src={colorPayment} className={styless.sectionIcon} />
                  <Box className={styless.title}>{t("recap_and_payment_title")}</Box>
                </Box>

                <Box className={styless.boxOne}>
                  <Box className={styless.notesBoxLogo}>
                    <img src={logo} style={{ width: "85px", height: "34px" }} />
                  </Box>

                  <Box className={styless.notesBoxMain}>
                    <Box className={styless.notesBox}>
                      <span className={styless.notes}>{t("tickets2")}</span>
                      <span className={styless.notes} style={{ fontWeight: "600" }}>
                        {ticketsChose === "multi" ? t("group1") : t("individual")}
                      </span>
                    </Box>
                    <Box className={styless.notesBox}>
                      <span className={styless.selectedTime}>{t("amount_of_tickets")}</span>
                      <span className={styless.selectedTimeRed}>{totalTickets}</span>
                    </Box>
                    <Box style={{ width: "100%" }}>
                      <Box className={styless.price_blockMain}>
                        {!!babyInfo && (
                          <span className={styless.price_block}>
                            <div>{babyInfo}</div>
                            <div
                              style={{ fontWeight: 600, color: "rgba(6, 39, 98, 1)" }}
                            >{`${babyTotalPrice} Kč`}</div>
                          </span>
                        )}
                        {!!childInfo && (
                          <span className={styless.price_block}>
                            <div>{childInfo}</div>
                            <div
                              style={{ fontWeight: 600, color: "rgba(6, 39, 98, 1)" }}
                            >{`${childTotalPrice} Kč`}</div>
                          </span>
                        )}
                        {!!adultInfo && (
                          <span className={styless.price_block}>
                            <div>{adultInfo}</div>{" "}
                            <div
                              style={{ fontWeight: 600, color: "rgba(6, 39, 98, 1)" }}
                            >{`${adultTotalPrice} Kč`}</div>
                          </span>
                        )}
                        {!!seniorInfo && (
                          <span className={styless.price_block}>
                            <div>{seniorInfo}</div>{" "}
                            <div
                              style={{ fontWeight: 600, color: "rgba(6, 39, 98, 1)" }}
                            >{`${seniorTotalPrice} Kč`}</div>
                          </span>
                        )}
                        {!!familyInfo && (
                          <span className={styless.price_block}>
                            <div>{familyInfo}</div>{" "}
                            <div
                              style={{ fontWeight: 600, color: "rgba(6, 39, 98, 1)" }}
                            >{`${familyTotalPrice} Kč`}</div>
                          </span>
                        )}
                        {!!ztpInfo && (
                          <span className={styless.price_block}>
                            <div>{ztpInfo}</div>{" "}
                            <div
                              style={{ fontWeight: 600, color: "rgba(6, 39, 98, 1)" }}
                            >{`${ztpTotalPrice} Kč`}</div>
                          </span>
                        )}
                        {!!freeInfo && (
                          <span className={styless.price_block}>
                            <div>{freeInfo}</div>{" "}
                            <div
                              style={{ fontWeight: 600, color: "rgba(6, 39, 98, 1)" }}
                            >{`${freeTotalPrice} Kč`}</div>
                          </span>
                        )}

                        {/* show discount if any applied */}
                        {localStorage.getItem("discount") && (
                          <span className={styless.price_block}>
                            <div>
                              {t("discount")}{" "}
                              {localStorage.getItem("discount") === "BRNOPASS"
                                ? "Brnopass30"
                                : "Školy10"}
                            </div>
                            <div style={{ fontWeight: 600, color: "rgba(6, 39, 98, 1)" }}>{`- ${
                              Number(localStorage.getItem("backupTotalPrice")) -
                              Number(localStorage.getItem("totalPrice"))
                            } Kč`}</div>
                          </span>
                        )}
                      </Box>
                    </Box>
                    <div className={styless.lineHoriz}></div>
                    <Box className={styless.notesBox}>
                      <span className={styless.notes}>{t("payment_overall")}</span>
                      <span
                        className={styless.notes}
                        style={{ fontWeight: "600", color: "#E31340" }}
                      >
                        {`${formValues.totalPrice} Kč`}
                      </span>
                    </Box>
                  </Box>
                  <Box className={styless.line} />
                  <Box className={styless.selectedBlock}>
                    <Box className={styless.selectedBlockInner}>
                      <span className={styless.selectedTime}>{t("final_time")}</span>
                      <span className={styless.selectedTime}>{t("final_date")}</span>
                    </Box>
                    <Box className={styless.selectedBlockInner}>
                      <span className={styless.selectedTimeRed}>{selectedTime}</span>
                      <span className={styless.selectedTimeRed}>{formattedDate}</span>
                    </Box>
                    <Box className={styless.notesBoxLogo2}>
                      <img src={logo} style={{ width: "120px" }} />
                    </Box>
                  </Box>
                </Box>
                <Box style={{ width: "100%" }}>
                  {isMobile ? <QRCodeMobile /> : <QRCodeDesktop />}
                </Box>
                <Box className={styless.boxThree}>
                  {showCheckboxAndButton ? (
                    <Box className={styless.boxThreeInner}>
                      <CheckboxComponent
                        onPaymentChange={handlePaymentChange}
                        isChecked={PaymentChecked}
                      />

                      <Box
                        className={`${styless.buttonToGo} ${
                          !PaymentChecked ? styless.buttonToGoDisabled : ""
                        }`}
                        style={{ textDecoration: "none" }}
                        onClick={onSubmit}
                      >
                        <span style={{ fontWeight: "400" }}>{t("go_to_bill")}</span>
                        <img src={arrow} />
                      </Box>
                    </Box>
                  ) : (
                    <Button
                      style={{
                        textDecoration: "none",
                      }}
                      sx={{
                        ...styles.buttonConfirmation,
                        "&:hover": {
                          backgroundColor: "#2F4D82",
                          color: "white",
                        },
                      }}
                    >
                      <Typography>{t("payment_confirmation")}</Typography>
                      <div style={{ marginTop: "3px" }}>
                        <img
                          src={cross}
                          style={{ marginLeft: "10px" }}
                          onClick={handleCrossClick}
                        />
                        <img
                          src={check}
                          style={{ marginLeft: "10px" }}
                          onClick={handleCheckClick}
                        />
                      </div>
                    </Button>
                  )}
                </Box>
              </Box>
            </Box>
          ) : (
            step.isStepFinished && (
              <Box className={styless.closedBox}>
                <img src={greyCard} />
                {t("recap_and_payment_title")}
                {step.isPen && <img className={styless.pen} src={penIcon} onClick={penClick} />}
              </Box>
            )
          )}
        </Box>
      )}
    </Box>
  );
};
