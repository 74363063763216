import axios from "axios";
import { USER_DATA_API_ROUTE } from "src/core/endpoints";
import { UserData } from "src/core/types/UserData";
import { useMutation } from "react-query";

export const useGetUserData = () => {
  const fetchUserData = async (token: string): Promise<UserData> => {
    const config = {
      headers: {
        Authorization:
          process.env.NODE_ENV === "production"
            ? `Basic ${window.btoa(
                `${process.env.REACT_APP_API_USERNAME}:${process.env.REACT_APP_API_PASSWORD}`,
              )}`
            : "",
      },
    };
    const res = await axios.post(
      `${window.BASE_API_URL}/${USER_DATA_API_ROUTE}/`,
      { token },
      config,
    );
    if (res.status !== 200) throw new Error(`User data request failed with code ${res.status}`);
    return res.data;
  };

  return useMutation(fetchUserData);
};
